import React, { cloneElement, Fragment, useEffect, useState } from "react";
import {
  Home,
  People,
  Logout,
  ExpandLess,
  ExpandMore,
  PeopleAlt,
  Map,
  Dashboard,
  CreditCard,
  MapOutlined,
  StarRateSharp,
  Search,
  EventAvailable,
  DataArray,
  DataObject,
  Help,
  DataUsage,
  AccountCircle,
} from "@mui/icons-material";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Box } from "@mui/material";
import Header from "../Util/Header";
import { Gear } from "@phosphor-icons/react";

const drawerWidth = 300;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "space-between",
  padding: theme.spacing(1, 0, 1, 1),
  backgroundColor: "#fff",
  color: "#2596be",
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  overflowY: "hidden",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Navbar(props) {
  const { user } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [openSections, setOpenSections] = useState({
    customerService: false,
    riskManagement: false,
    debtCollection: false,
  });
  const [menuItems, setMenuItems] = useState([]);

  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);

  const handleToggle = (section) => {
    setOpenSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const logout = () => {
    fetch(`/api/admin/logout`).then((res) => {
      navigate("/");
    });
  };

  const adminItems = [
    {
      text: "Plots",
      path: "/plots",
      icon: <Map />,
    },
    {
      text: "Payments",
      path: "/payments",
      icon: <CreditCard />,
    },
    {
      text: "Help Desk",
      path: "/support",
      icon: <Help />,
    },
    {
      text: "Search Request",
      path: "/search",
      icon: <Search />,
    },
  ];

  useEffect(() => {
    if (user) {
      // if (
      //   user.Department ===
      //   "Lands, Physical Planning, Housing and Urban Development"
      // ) {
      //   setMenuItems(adminItems);
      // } else if (user.Department === "ICT") {
      //   // setMenuItems(ICTItems);
      // } else if (user.Department === "Finance and Economic Planning") {
      //   // setMenuItems(financeItems);
      // }
      setMenuItems(adminItems);
    }
  }, [user]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <Header
            setUser={props.setUser}
            handleDrawerOpen={handleDrawerOpen}
            open={open}
          />
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <Box></Box>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {menuItems.map((item) => (
            <Fragment key={item.text}>
              {item.subItems ? (
                <>
                  <ListItem button onClick={() => handleToggle(item.text)}>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText
                      sx={{
                        fontSize: "small",
                        color:
                          location.pathname === item.path
                            ? "primary"
                            : "textSecondary",
                        fontWeight:
                          location.pathname === item.path ? "bold" : "normal", // Highlight text for selected item
                      }}
                      primary={item.text}
                    />
                    {openSections[item.text] ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  {openSections[item.text] && (
                    <List component="div" disablePadding>
                      {item.subItems.map((subItem) => (
                        <ListItem
                          key={subItem.text}
                          button
                          onClick={() => navigate(subItem.path)}
                          selected={location.pathname === subItem.path}
                          sx={{
                            fontSize: "x-small",
                            pl: 4, // Indent subitems
                            typography: "body2", // Reduce font size
                            fontStyle: "italic", // Italicize text
                            bgcolor:
                              location.pathname === subItem.path
                                ? "action.selected"
                                : "transparent", // Highlight selected subitem
                          }}
                        >
                          <ListItemIcon>{subItem.icon}</ListItemIcon>
                          <ListItemText
                            primary={subItem.text}
                            sx={{
                              fontSize: "x-small",
                              color:
                                location.pathname === item.path
                                  ? "primary"
                                  : "textSecondary",
                              fontWeight:
                                location.pathname === item.path
                                  ? "bold"
                                  : "normal", // Highlight text for selected item
                            }}
                          />
                        </ListItem>
                      ))}
                    </List>
                  )}
                </>
              ) : (
                <ListItem
                  key={item.text}
                  button
                  onClick={() => navigate(item.path)}
                  selected={location.pathname === item.path}
                  sx={{
                    cursor: "pointer",
                    bgcolor:
                      location.pathname === item.path
                        ? "action.selected"
                        : "transparent", // Highlight selected item
                  }}
                >
                  <ListItemIcon>
                    {cloneElement(item.icon, {
                      color:
                        location.pathname === item.path
                          ? "primary"
                          : "textSecondary",
                    })}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.text}
                    sx={{
                      cursor: "pointer",
                      color:
                        location.pathname === item.path
                          ? "primary"
                          : "textSecondary",
                      fontWeight:
                        location.pathname === item.path ? "bold" : "normal", // Highlight text for selected item
                    }}
                  />
                </ListItem>
              )}
            </Fragment>
          ))}
        </List>
        <Divider />
        <List>
          {user &&
            user.Department ===
              "Lands, Physical Planning, Housing and Urban Development" && (
              <ListItem
                button
                sx={{ cursor: "pointer" }}
                onClick={() => navigate("/users")}
              >
                <ListItemIcon>
                  <PeopleAlt />
                </ListItemIcon>
                <ListItemText primary="Users" />
              </ListItem>
            )}
          <ListItem
            button
            onClick={() => navigate("/settings")}
            sx={{ cursor: "pointer" }}
          >
            <ListItemIcon>
              <Gear size={24} />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItem>
          <ListItem button onClick={logout} sx={{ cursor: "pointer" }}>
            <ListItemIcon>
              <Logout />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
    </Box>
  );
}
