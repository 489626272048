import React, { useEffect, useState } from "react";
import {
  Card,
  Typography,
  Button,
  Divider,
  Box,
  Stack,
  CardContent,
  Collapse,
  Chip,
  Grid2,
  Paper,
  Container,
} from "@mui/material";
import SingleParcel from "../components/parcels/SingleParcel";
import { ArrowForwardIos } from "@mui/icons-material";

export default function Plots() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [expandedLoanId, setExpandedLoanId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const limit = 10;
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const ownerId = localStorage.getItem("ownershipId");

  useEffect(() => {
    const fetchOwner = async () => {
      try {
        const response = await fetch(
          `/api/ownerships?id=${ownerId}&includeBeneficiaries=true`,
          {
            credentials: "include",
          }
        );
        if (!response.ok) throw new Error("Failed to fetch owner details");
        const { data } = await response.json();
        setUser(data[0]);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchOwner();
  }, [ownerId]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const offset = (currentPage - 1) * limit;
      const response = await fetch(
        `/api/parcellink?ownershipId=${user.id}&offset=${offset}&limit=${limit}`,
        {
          credentials: "include",
        }
      );
      if (!response.ok) throw new Error("Failed to fetch payments");
      const data = await response.json();
      setData(data);
      // Set the first item to be expanded by default
      if (data?.data?.length > 0) {
        setExpandedLoanId(data.data[0].id);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user?.id) {
      fetchData();
    }
  }, [user?.id, currentPage]);

  const handleExpandClick = (loanId) => {
    setExpandedLoanId((prevId) => (prevId === loanId ? null : loanId));
  };

  return (
    <Paper elevation={2} sx={{ p: 2, mt: 6 }}>
      <Box>
        <Typography
          variant="h6"
          color="primary"
          gutterBottom
          sx={{ fontWeight: 600, padding: " 0.5rem 1rem" }}
        >
          Land Parcels
        </Typography>
        <Stack spacing={2}>
          {data?.data?.map((link) => (
            <Card
              key={link.id}
              sx={{
                position: "relative",
                boxShadow: "0px 4px 16px #60606040",
                borderRadius: "8px",
              }}
            >
              <CardContent>
                <Typography variant="h6" color="primary" gutterBottom>
                  Plot Number: {link.landparcels?.plotNo}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  <strong style={{ fontWeight: 500 }}>Start Date:</strong>{" "}
                  {new Date(link.startDate).toLocaleDateString()}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  <strong style={{ fontWeight: 500 }}>End Date:</strong>{" "}
                  {link.endDate
                    ? new Date(link.endDate).toLocaleDateString()
                    : "Ongoing"}
                </Typography>
                <Chip
                  label={link.isActive ? "Active" : "Inactive"}
                  sx={{ position: "absolute", top: "1em", right: "1em" }}
                  color={link.isActive ? "success" : "default"}
                />
                <Button
                  sx={{ textTransform: "capitalize" }}
                  variant="outlined"
                  size="small"
                  onClick={() => handleExpandClick(link.id)}
                >
                  {expandedLoanId === link.id ? (
                    <ArrowForwardIos
                      sx={{ rotate: "-90deg", fontSize: "small", mr: 1 }}
                    />
                  ) : (
                    <ArrowForwardIos
                      sx={{ rotate: "90deg", fontSize: "small", mr: 1 }}
                    />
                  )}{" "}
                  {expandedLoanId === link.id ? "Hide Details" : "View Details"}
                </Button>
              </CardContent>
              <Collapse
                in={expandedLoanId === link.id}
                timeout="auto"
                unmountOnExit
              >
                <CardContent>
                  {/* Land Parcel Details Section */}
                  <Typography variant="subtitle1" sx={{ mt: 2 }}>
                    Land Parcel Details
                  </Typography>
                  <Divider sx={{ my: 1 }} />
                  <Grid2 container spacing={3}>
                    <Grid2 size={{ xs: 12, md: 6 }}>
                      <Typography variant="body2" gutterBottom>
                        <strong style={{ fontWeight: 500 }}>
                          Plot Number:
                        </strong>{" "}
                        {link.landparcels?.plotNo || "N/A"}
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        <strong style={{ fontWeight: 500 }}>FR Number:</strong>{" "}
                        {link.landparcels?.frNo || "N/A"}
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        <strong style={{ fontWeight: 500 }}>Area:</strong>{" "}
                        {link.landparcels?.area} hectares
                      </Typography>
                    </Grid2>
                    <Grid2 size={{ xs: 12, md: 6 }}>
                      <SingleParcel
                        geom={link.landparcels?.geom}
                        area={link.landparcels?.area}
                      />
                    </Grid2>
                  </Grid2>
                  {/* Owner Details Section */}
                  <Typography variant="subtitle1" sx={{ mt: 2 }}>
                    Owner Details
                  </Typography>
                  <Divider sx={{ my: 1 }} />
                  <Typography variant="body2" gutterBottom>
                    <strong style={{ fontWeight: 500 }}>Gender:</strong>{" "}
                    {link.owners?.gender}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    <strong style={{ fontWeight: 500 }}>Ownership Type:</strong>{" "}
                    {link.owners?.ownershipType}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    <strong style={{ fontWeight: 500 }}>County:</strong>{" "}
                    {link.owners?.county}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    <strong style={{ fontWeight: 500 }}>
                      Physical Address:
                    </strong>{" "}
                    {link.owners?.physicalAddress}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    <strong style={{ fontWeight: 500 }}>Email:</strong>{" "}
                    {link.owners?.email}
                  </Typography>
                </CardContent>
              </Collapse>
            </Card>
          ))}
        </Stack>
      </Box>
    </Paper>
  );
}
