import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Container,
} from "@mui/material";
import Logo from "../../assets/imgs/lg.png";
import RegisterPopup from "../../components/Home/RegisterPopup";
import LoginPopup from "../../components/Home/LoginPopup";

const LoadingScreen = () => (
  <Box
    sx={{
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(255, 255, 255, 1)",
      zIndex: 1300,
    }}
  >
    <CircularProgress />
  </Box>
);

export default function Header(props) {
  const [currentUser, setCurrentUser] = useState("");
  const [loading, setLoading] = useState(true);
  const [registerOpen, setRegisterOpen] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);

  const handleRegisterOpen = () => setRegisterOpen(true);
  const handleRegisterClose = () => setRegisterOpen(false);

  const handleLoginOpen = () => setLoginOpen(true);
  const handleLoginClose = () => setLoginOpen(false);

  useEffect(() => {
    setLoading(true);
    fetch("/api/admin/mydetails", {
      method: "GET",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
        throw new Error();
      })
      .then((data) => {
        setCurrentUser(data);
        props.setUser(data);
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  }, []);

  return (
    <Container disableGutters sx={{ px: 0 }}>
      {loading && <LoadingScreen />}

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: 2,
          color: "#596370",
          width: "100%",
        }}
      >
        <Box sx={{ mr: 5, flexGrow: 1 }}>
          <Box sx={{ flexGrow: 1, color: "#000", fontWeight: "bold" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img src={Logo} alt="logo" style={{ height: "50px" }} />
              <Box sx={{ ml: 2 }}>
                <Typography color="primary" sx={{ fontWeight: "800" }}>
                  Homabay County
                </Typography>
                <Typography variant="body2">LIMS Public View</Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* Register Popup */}
        <RegisterPopup open={registerOpen} handleClose={handleRegisterClose} />

        {/* Login Popup, ensure it is shown when loginOpen is true */}
        {loginOpen && (
          <LoginPopup open={loginOpen} handleClose={handleLoginClose} />
        )}

        <Box sx={{ mr: 5 }}>
          <Button
            variant="contained"
            color="primary"
            sx={{ marginRight: 2 }}
            onClick={handleRegisterOpen}
          >
            Register
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleLoginOpen} // Open Login Popup when clicked
          >
            Login
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
