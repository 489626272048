import React from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Container,
} from "@mui/material";
import Lands from "../../assets/imgs/lands.png";
import Sok from "../../assets/imgs/sok.jpg";
import Logo from "../../assets/imgs/lg.png";
import Grid from "@mui/material/Grid2";

const partners = [
  {
    name: "Homa-Bay County",
    logo: Logo,
    description: "Collaborating for sustainable land and resource management.",
  },
  {
    name: "Ministry of Lands",
    logo: Lands,
    description: "Ensuring equitable land distribution and secure tenure.",
  },
  {
    name: "Survey of Kenya",
    logo: Sok,
    description:
      "Empowering communities for growth and sustainable development.",
  },
];

export default function PartnerSection() {
  return (
    <Box sx={{ padding: 4, backgroundColor: "#f5f5f5" }}>
      <Container>
        <Typography
          variant="h4"
          sx={{ textAlign: "center", fontWeight: 600, color: "#061e06", mb: 4 }}
        >
          Our Valued Partners
        </Typography>

        <Grid container spacing={4} justifyContent="center">
          {partners.map((partner, index) => (
            <Grid size={4} key={index}>
              <Card
                sx={{
                  borderRadius: "12px",
                  boxShadow: 3,
                  transition: "transform 0.3s",
                  "&:hover": { transform: "scale(1.05)" },
                }}
              >
                <CardMedia
                  component="img"
                  height="140"
                  image={partner.logo}
                  alt={partner.name}
                  sx={{
                    objectFit: "contain",
                    padding: 2,
                    backgroundColor: "#fff",
                  }}
                />
                <CardContent sx={{ textAlign: "center" }}>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: 600, color: "primary.main", mb: 1 }}
                  >
                    {partner.name}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#555" }}>
                    {partner.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
