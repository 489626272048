import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";

const RegisterPopup = ({ open, handleClose }) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [phoneField, setPhoneField] = useState("");
  const [idField, setIdField] = useState("");

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    ownershipId: "",
    phone: "",
    password: "",
    confirmPassword: "",
  });

  useEffect(() => {
    if (phoneField) {
      const fetchUserDetails = async () => {
        try {
          const response = await fetch(`/api/ownerships/id/${phoneField}`);
          if (!response.ok) {
            throw new Error("Failed to fetch ownership details");
          }
          const data = await response.json();

          if (data?.id) {
            setIdField(data.id);
            setFormData((prevData) => ({
              ...prevData,
              name: data.name || "",
              email: data.email || "",
            }));
          } else {
            setIdField("Proceed to register");
          }
        } catch (err) {
          if (err.message !== "Failed to fetch ownership details") {
            setError("Error fetching ownership details");
          }
          setIdField("Proceed to register");
        }
      };

      fetchUserDetails();
    } else {
      setIdField("");
    }
  }, [phoneField]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone") {
      setPhoneField(value);
    }
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async () => {
    if (
      !formData.name ||
      !formData.email ||
      !formData.phone ||
      !formData.password ||
      !formData.confirmPassword
    ) {
      setError("All fields except Ownership ID are required.");
      return;
    }
    if (formData.password !== formData.confirmPassword) {
      setError("Passwords do not match.");
      return;
    }
    setError("");
    setLoading(true);
    const formDataToSend = {
      ...formData,
      ownershipId:
        idField && idField !== "Proceed to register" ? idField : null,
    };
    try {
      const response = await fetch("/api/public/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formDataToSend),
      });
      const data = await response.json();
      if (!response.ok) {
        setError(data.message || "Failed to register user");
        return;
      }
      setSuccessMessage("User successfully registered!");
      setFormData({
        name: "",
        email: "",
        ownershipId: "",
        phone: "",
        password: "",
        confirmPassword: "",
      });
      setTimeout(() => {
        setSuccessMessage("");
        handleClose();
      }, 3000);
    } catch (err) {
      setError("An error occurred while registering.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography variant="h5" color="primary" fontWeight="bold">
          Register New User
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={2}>
          <TextField
            label="Phone"
            name="phone"
            type="tel"
            value={formData.phone}
            onChange={handleChange}
            fullWidth
            required
          />
          <TextField
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            fullWidth
            required
          />
          <TextField
            label="Email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            fullWidth
            required
          />
          <TextField
            label="Password"
            name="password"
            type="password"
            value={formData.password}
            onChange={handleChange}
            fullWidth
            required
          />
          <TextField
            label="Confirm Password"
            name="confirmPassword"
            type="password"
            value={formData.confirmPassword}
            onChange={handleChange}
            fullWidth
            required
          />
          {error && (
            <Typography color="error" variant="body2" sx={{ marginTop: 2 }}>
              {error}
            </Typography>
          )}
          {successMessage && (
            <Typography color="success" variant="body2" sx={{ marginTop: 2 }}>
              {successMessage}
            </Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            "Register"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RegisterPopup;
