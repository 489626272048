import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Collapse,
  Divider,
  Stack,
  Chip,
  CircularProgress,
  Pagination,
  Grid2,
  Container,
  Paper,
} from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";

export default function Payments() {
  const [loading, setLoading] = useState(true);
  const [payments, setPayments] = useState([]);
  const [expandedPaymentId, setExpandedPaymentId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const limit = 10;

  // fetch owner details to use as user object for the billing data
  const [user, setUser] = useState(null);
  const ownerId = localStorage.getItem("ownershipId");

  useEffect(() => {
    const fetchOwner = async () => {
      try {
        const response = await fetch(
          `/api/ownerships?id=${ownerId}&includeBeneficiaries=true`,
          {
            credentials: "include",
          }
        );
        if (!response.ok) throw new Error("Failed to fetch owner details");
        const { data } = await response.json();

        setUser(data[0]);
      } catch (error) {
        console.error(error);
      }
    };

    fetchOwner();
  }, [ownerId]);

  const fetchPayments = async () => {
    setLoading(true);
    try {
      const offset = (currentPage - 1) * limit;
      const response = await fetch(
        `/api/billings?phoneNumber=${user?.phoneNumber}&offset=${offset}&limit=${limit}`,
        {
          credentials: "include",
        }
      );
      if (!response.ok) throw new Error("Failed to fetch payments");
      const { data } = await response.json();
      setPayments(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPayments();
  }, [user?.id, currentPage]);

  const handleExpandClick = (paymentId) => {
    setExpandedPaymentId((prevId) => (prevId === paymentId ? null : paymentId));
  };

  const handlePageChange = (_, page) => {
    setCurrentPage(page);
  };

  if (loading) {
    return (
      <Box sx={{ display: "grid", placeItems: "center", height: "50vh" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
      <Paper elevation={2} sx={{ p: 2, mt: 6}}>
        <Typography variant="h6" color="primary" sx={{ fontWeight: "600" }}>
          Payments
        </Typography>
        <Stack spacing={2}>
          {payments.map((payment) => (
            <Card
              key={payment.id}
              sx={{
                position: "relative",
                boxShadow: "0px 4px 16px #60606040",
                borderRadius: "8px",
              }}
            >
              <CardContent sx={{ mb: 0, pb: 0 }}>
                <Typography
                  sx={{ position: "absolute", top: "1.5em", right: "1em" }}
                  variant="body2"
                  color="text.secondary"
                >
                  {new Date(payment?.createdAt).toLocaleString()}
                </Typography>

                <Typography variant="h6" color="primary" mb={1}>
                  KSh {payment?.billingAmount}
                </Typography>

                <Chip
                  label={`Billed on ${new Date(
                    payment.dateBilled
                  ).toLocaleDateString()}`}
                  sx={{ position: "absolute", bottom: "1.5em", right: "1em" }}
                />
                <Button
                  size="small"
                  variant="outlined"
                  sx={{ textTransform: "capitalize" }}
                  onClick={() => handleExpandClick(payment.id)}
                >
                  {expandedPaymentId === payment.id ? (
                    <ArrowForwardIos
                      sx={{ rotate: "-90deg", fontSize: "small", mr: 1 }}
                    />
                  ) : (
                    <ArrowForwardIos
                      sx={{ rotate: "90deg", fontSize: "small", mr: 1 }}
                    />
                  )}{" "}
                  {expandedPaymentId === payment.id
                    ? "Hide Details"
                    : "View Details"}
                </Button>
              </CardContent>
              <Collapse
                in={expandedPaymentId === payment.id}
                timeout="auto"
                unmountOnExit
              >
                <CardContent>
                  <Divider />
                  <Grid2 size={{ xs: 12, md: 6 }}>
                    <Typography variant="subtitle1" sx={{ mt: 2 }}>
                      Billed Account Details
                    </Typography>
                    <Divider sx={{ my: 1 }} />
                    <Typography variant="body2" gutterBottom>
                      <strong style={{ fontWeight: 500 }}>National ID:</strong>{" "}
                      {payment.nationalId}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      <strong style={{ fontWeight: 500 }}>Phone Number:</strong>{" "}
                      {payment.phoneNumber}
                    </Typography>
                  </Grid2>
                  <Grid2 size={{ xs: 12, md: 6 }}>
                    <Typography variant="subtitle1" sx={{ mt: 2 }} gutterBottom>
                      Rate Details
                    </Typography>
                    <Divider sx={{ my: 1 }} />
                    <Typography variant="body2" gutterBottom>
                      <strong style={{ fontWeight: 500 }}>Land Rent:</strong>{" "}
                      KSh {(payment.landRent - 0).toLocaleString()}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      <strong style={{ fontWeight: 500 }}>Land Rate:</strong>{" "}
                      KSh {(payment.landRate - 0).toLocaleString()}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      <strong style={{ fontWeight: 500 }}>Arrears:</strong> KSh{" "}
                      {(payment.arrears - 0).toLocaleString()}
                    </Typography>
                  </Grid2>
                </CardContent>
              </Collapse>
            </Card>
          ))}
        </Stack>
        <Pagination
          count={Math.ceil(payments.length / limit) || 1}
          page={currentPage}
          onChange={handlePageChange}
          sx={{ mt: 3 }}
        />
      </Paper>
  );
}
