import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  Alert,
  Divider,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

export default function EnquiryDialog({ open, onClose, refresh, setRefresh }) {
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  // Reset form when dialog opens
  useEffect(() => {
    if (open) {
      setTitle("");
      setCategory("");
      setPhone("");
      setMessage("");
      setError("");
      setSuccess("");
    }
  }, [open]);
    
    const [user, setUser] = useState(null);
    const ownerId = localStorage.getItem("ownershipId");

    useEffect(() => {
      const fetchOwner = async () => {
        try {
          const response = await fetch(
            `/api/ownerships?id=${ownerId}&includeBeneficiaries=true`,
            {
              credentials: "include",
            }
          );
          if (!response.ok) throw new Error("Failed to fetch owner details");
          const { data } = await response.json();

            setUser(data[0]);
            console.log(data[0]);
            
        } catch (error) {
          console.error(error);
        }
      };

      fetchOwner();
    }, [ownerId]);

  const handleSubmit = async () => {
    if (!title || !category || !message) {
      setError("All fields are required.");
      return;
    }

    const data = {
      title,
      category,
      phone: user ? user.phoneNumber : null,
      message,
    //   email: user ? user.email : null,
      name: user ? user.name : null,
      ownershipId: ownerId,
    };

    try {
      setLoading(true);
      setError("");
      setSuccess("");
      const response = await fetch("/api/enquiries", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        setSuccess("Enquiry created successfully!");
        setTitle("");
        setCategory("");
        setPhone("");
        setMessage("");
        setTimeout(() => {
          setRefresh(!refresh);
          onClose();
        }, 1000);
      } else throw new Error("Failed to create enquiry");
    } catch (err) {
      setError("Failed to create enquiry, please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle color="primary">Create a Search Request</DialogTitle>
      <Divider />
      <DialogContent>
        <Box mb={2}>
          <Typography
            variant="body1"
            color="textSecondary"
          >
            {user?.name}
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
          >
            {user?.email}
          </Typography>
          <TextField
            label="Title"
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            sx={{ marginBottom: 2 }}
          />
          <FormControl fullWidth sx={{ marginBottom: 2 }} required>
            <InputLabel>Category</InputLabel>
            <Select
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              <MenuItem value="SearchRequest">Search Request</MenuItem>
            </Select>
          </FormControl>

          <TextField
            label="Message"
            fullWidth
            multiline
            rows={4}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          />
        </Box>
        {error && <Alert severity="warning">{error}</Alert>}
        {success && <Alert severity="success">{success}</Alert>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={loading}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          disabled={loading}
        >
          {loading ? <CircularProgress size={20} /> : "Submit"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
