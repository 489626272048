import React, { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import Link from "@mui/material/Link";
import OutlinedInput from "@mui/material/OutlinedInput";
import Stack from "@mui/material/Stack";
import {
  Eye as EyeIcon,
  EyeSlash as EyeSlashIcon,
} from "@phosphor-icons/react";
import { Controller, useForm } from "react-hook-form";
import { z as zod } from "zod";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import ForgotPassword from "../ForgotPassword";

const schema = zod.object({
  phone: zod
    .string()
    .min(1, { message: "Phone number is required" })
    .regex(/^\d{9}$/, { message: "Phone number must be 9 digits" }),
  password: zod.string().min(1, { message: "Password is required" }),
});

const defaultValues = { phone: "", password: "" };

export default function LoginPage(props) {
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues,
  });

  const [showPassword, setShowPassword] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [severity, setSeverity] = useState("error");
  const [isError, setIsError] = useState("");
  const [showing, setShowing] = useState(false);
  const [open, setOpen] = useState(true); // State to control the Login dialog visibility

  const handleClose = () => {
    setOpen(false); // Close the dialog
  };

  const onSubmit = async (data) => {
    setIsError("");
    setSeverity("error");
    setIsPending(true);
    try {
      const response = await fetch("/api/public/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ phone: data.phone, password: data.password }),
      });

      setIsPending(false);
      const body = await response.json();

      if (response.ok) {
        setSeverity("success");
        localStorage.setItem("authToken", body.token);
        localStorage.setItem("userId", body.public.id);
        localStorage.setItem("ownershipId", body.public.ownershipId);
        setIsError(body?.message ?? "Login successful");
        setTimeout(() => {
          navigate("/plots");
        }, 500);
      } else {
        setIsError(body?.message ?? "Login failed");
      }
    } catch (error) {
      setIsError("Oops! Could not establish connection!");
    }
  };

  return (
    <Box>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ textAlign: "center", color: "#0033A0" }}>
          Sign in
        </DialogTitle>
        <DialogContent>
          <form style={{ marginTop: "10px" }} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2}>
              <Controller
                control={control}
                name="phone"
                render={({ field }) => (
                  <FormControl error={Boolean(errors.phone)} fullWidth>
                    <InputLabel>Phone number</InputLabel>
                    <OutlinedInput {...field} label="Phone number" />
                    {errors.phone && (
                      <FormHelperText>{errors.phone.message}</FormHelperText>
                    )}
                  </FormControl>
                )}
              />
              <Controller
                control={control}
                name="password"
                render={({ field }) => (
                  <FormControl error={Boolean(errors.password)} fullWidth>
                    <InputLabel>Password</InputLabel>
                    <OutlinedInput
                      {...field}
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        showPassword ? (
                          <EyeIcon
                            cursor="pointer"
                            onClick={() => setShowPassword(false)}
                          />
                        ) : (
                          <EyeSlashIcon
                            cursor="pointer"
                            onClick={() => setShowPassword(true)}
                          />
                        )
                      }
                      label="Password"
                    />
                    {errors.password && (
                      <FormHelperText>{errors.password.message}</FormHelperText>
                    )}
                  </FormControl>
                )}
              />
              <div>
                <Link
                  component={RouterLink}
                  onClick={() => setShowing(true)}
                  variant="subtitle2"
                  sx={{ textAlign: "center", color: "#0033A0" }}
                >
                  Forgot password?
                </Link>
              </div>
              {isError && <Alert severity={severity}>{isError}</Alert>}
              <Button
                disabled={isPending}
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                startIcon={isPending ? <CircularProgress size={24} /> : null}
                fullWidth
              >
                {isPending ? "Signing in..." : "Sign in"}
              </Button>
            </Stack>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {" "}
            {/* Close Button */}
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Forgot Password Dialog */}
      <Dialog open={showing} onClose={() => setShowing(false)}>
        <DialogTitle>Forgot Password?</DialogTitle>
        <DialogContent>
          <ForgotPassword />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowing(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
