import React from "react";
import {
  Box,
  Typography,
  Link,
  List,
  ListItem,
  ListItemText,
  Container,
} from "@mui/material";
import {
  Facebook,
  Twitter,
  Email,
  Phone,
  Instagram,
  YouTube,
} from "@mui/icons-material";
import Grid2 from "@mui/material/Grid2";
import Logo from "../../assets/imgs/lg.png";

const contactItems = [
  {
    icon: <Facebook sx={{ mr: 1 }} />,
    label: "Facebook",
    link: "https://www.facebook.com/people/County-Government-Of-Homa-Bay/100095138725427/",
  },
  {
    icon: <Instagram sx={{ mr: 1 }} />,
    label: "Instagram",
    link: "https://www.instagram.com/county_goverment_of_homabay/?igsh=dWlyd2c3bWc5bjM2",
  },
  {
    icon: <YouTube sx={{ mr: 1 }} />,
    label: "YouTube",
    link: "https://www.youtube.com/channel/UCFY_ha43Pjao-JLeQJPSTpg",
  },
  {
    icon: <Twitter sx={{ mr: 1 }} />,
    label: "Twitter",
    link: "https://x.com/HomaBayCountyKE",
  },
  {
    icon: <Email sx={{ mr: 1 }} />,
    label: "governor@homabay.go.ke",
    link: "mailto:governor@homabay.go.ke",
  },
  {
    icon: <Phone sx={{ mr: 1 }} />,
    label: "0800 000 870",
    link: null,
  },
];

export default function FooterSection() {
  return (
    <Box
      sx={{
        backgroundColor: "rgba(0, 0, 0, 0.9)",
        color: "#fff",
        fontWeight: "800",
        py: 4,
      }}
    >
      <Container disableGutters sx={{ px: 0 }}>
        <Grid2 container spacing={6} justifyContent="center">
          {/* Logo and Name */}
          <Grid2 item xs={12} sm={6} md={3}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <img
                src={Logo}
                alt="Homabay LIMS Public Portal"
                width={80}
                height={80}
                style={{ marginRight: 10 }}
              />
              <Typography
                variant="body1"
                sx={{ fontWeight: 800, color: "#E06126" }}
              >
                Homabay LIMS Public Portal
              </Typography>
            </Box>
            <Typography
              variant="body2"
              sx={{
                maxWidth: 300,
                textAlign: "justify",
                lineHeight: 1.5,
              }}
            >
              Empowering secure land tenure and sustainable resource management.
            </Typography>
          </Grid2>

          {/* Quick Links */}
          <Grid2 item xs={12} sm={6} md={3}>
            <Typography variant="h6" sx={{ mb: 2, color: "#E06126" }}>
              Legal Information
            </Typography>
            <List>
              {[
                { label: "Terms & Conditions", path: "/terms" },
                { label: "Privacy Policy", path: "/privacy" },
              ].map((item, index) => (
                <ListItem key={index} disablePadding>
                  <Link
                    href={item.path}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      color: "#fff",
                      textDecoration: "none",
                      "&:hover": { color: "#E06126" },
                    }}
                  >
                    <ListItemText primary={item.label} />
                  </Link>
                </ListItem>
              ))}
            </List>
          </Grid2>

          {/* Our Partners */}
          <Grid2 item xs={12} sm={6} md={3}>
            <Typography variant="h6" sx={{ mb: 2, color: "#E06126" }}>
              Our Partners
            </Typography>
            <List>
              {[
                { name: "Homa-Bay County", url: "https://www.homabay.go.ke/" },
                { name: "Ministry of Lands", url: "https://lands.go.ke" },
                {
                  name: "Survey of Kenya",
                  url: "https://www.un-spider.org/survey-kenya-sok",
                },
              ].map((partner, index) => (
                <ListItem key={index} disablePadding>
                  <Link
                    href={partner.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      color: "#fff",
                      textDecoration: "none",
                      "&:hover": {
                        color: "#E06126",
                        textDecoration: "underline",
                      },
                    }}
                  >
                    <ListItemText primary={partner.name} />
                  </Link>
                </ListItem>
              ))}
            </List>
          </Grid2>

          {/* Contact and Social Media */}
          <Grid2 item xs={12} sm={6} md={3}>
            <Typography variant="h6" sx={{ mb: 2, color: "#E06126" }}>
              Contact Us
            </Typography>
            <List>
              {contactItems.map((item, index) => (
                <ListItem
                  key={index}
                  disablePadding
                  sx={{ display: "flex", alignItems: "center", mb: 1 }}
                >
                  {item.icon}
                  {item.link ? (
                    <Link
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{
                        color: "#fff",
                        textDecoration: "none",
                        "&:hover": { color: "#E06126" },
                      }}
                    >
                      {item.label}
                    </Link>
                  ) : (
                    <Typography variant="body2" sx={{ color: "#fff" }}>
                      {item.label}
                    </Typography>
                  )}
                </ListItem>
              ))}
            </List>
          </Grid2>
        </Grid2>

        {/* Copyright */}
        <Typography variant="body2" sx={{ textAlign: "center", mt: 3 }}>
          &copy; {new Date().getFullYear()} Homabay LIMS Public Portal. All
          rights reserved.
        </Typography>
      </Container>
    </Box>
  );
}
