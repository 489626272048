import React, { useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Container,
} from "@mui/material";
import Grid2 from "@mui/material/Grid2";

const newsArticles = [
  {
    title: "Breaking News: Local Event Happens",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur vehicula...",
    fullContent:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur vehicula, eros eget tempor consequat, justo eros tincidunt libero, id vestibulum lorem lectus vitae sapien. Nulla facilisi. Sed faucibus augue neque, sed pellentesque felis vestibulum et.",
  },
  {
    title: "New Initiative Launched by Organization",
    description:
      "Pellentesque scelerisque urna eu turpis fermentum, id vestibulum lorem tincidunt...",
    fullContent:
      "Pellentesque scelerisque urna eu turpis fermentum, id vestibulum lorem tincidunt, scelerisque ipsum odio at justo. Nunc a eros at arcu gravida tempus. Quisque tincidunt dui non efficitur egestas. Fusce sagittis aliquam felis.",
  },
  {
    title: "Community Gathering Highlights News",
    description:
      "Sed euismod ante eget neque varius, vitae ultricies arcu sollicitudin. Ut fringilla...",
    fullContent:
      "Sed euismod ante eget neque varius, vitae ultricies arcu sollicitudin. Ut fringilla augue at mauris luctus, ut aliquet nulla varius. Morbi placerat, nunc a vehicula pharetra, ipsum dui accumsan urna, eget convallis justo elit et dui.",
  },
];

const NewsSection = () => {
  const [open, setOpen] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState(null);

  const handleOpen = (article) => {
    setSelectedArticle(article);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedArticle(null);
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ padding: 2, margin: "6rem 0 2rem 0" }}>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 600,
            color: "#061e06",
            marginBottom: "2rem",
            textAlign: "center",
          }}
        >
          Latest and Events News
        </Typography>
        <Divider sx={{ marginBottom: "2rem" }} />
        {newsArticles.length === 0 ? (
          <Typography
            variant="body1"
            sx={{ textAlign: "center", color: "#555", fontWeight: 500 }}
          >
            No news to display.
          </Typography>
        ) : (
          <Grid2 container spacing={3}>
            {newsArticles.map((article, index) => (
              <Grid2 size={4} key={index}>
                {" "}
                {/* 3 items per row */}
                <Card sx={{ borderRadius: "8px", boxShadow: 3 }}>
                  <CardContent>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 600,
                        color: "#061e06",
                        marginBottom: "0.5rem",
                      }}
                    >
                      {article.title}
                    </Typography>

                    <Typography
                      variant="body2"
                      sx={{ color: "#555", marginBottom: "1rem" }}
                    >
                      {article.description}
                    </Typography>
                    <Button
                      variant="outlined"
                      sx={{
                        textTransform: "none",
                        borderColor: "#F5A721",
                        color: "#F5A721",
                        "&:hover": {
                          backgroundColor: "#F5A721",
                          color: "#fff",
                          borderColor: "#F5A721",
                        },
                      }}
                      onClick={() => handleOpen(article)}
                    >
                      Read More
                    </Button>
                  </CardContent>
                </Card>
              </Grid2>
            ))}
          </Grid2>
        )}
        <Dialog open={open} onClose={handleClose} fullWidth>
          <DialogTitle>{selectedArticle?.title}</DialogTitle>
          <DialogContent>
            <Typography variant="body1" sx={{ color: "#555" }}>
              {selectedArticle?.fullContent}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              sx={{
                color: "#F5A721",
                "&:hover": { backgroundColor: "#F5A721", color: "#fff" },
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Container>
  );
};

export default NewsSection;
