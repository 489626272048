import React from "react";
import {
  Typography,
  Container,
  Paper,
  Divider,
  Box,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import Header from "../Header/Header";
import Footer from "../../components/Home/FooterSection";
import HeroSection from "../Home/HeroSection";


export default function TermsAndConditions() {
  return (
    <>
      <Header />
      <HeroSection />
      <Container maxWidth="md" sx={{ mt: 12, mb: 3 }}>
        <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
          <Typography variant="h4" color="primary" gutterBottom align="center">
            Terms and Conditions
          </Typography>
          <Divider sx={{ mb: 3 }} />

          {/* System Use Section */}
          <Box mb={4}>
            <Typography variant="h5" gutterBottom>
              1. System Use
            </Typography>
            <Typography variant="body1">
              By accessing the Homabay Land Information Management System, users
              agree to use the platform solely for legal purposes related to
              land parcel management. Unauthorized attempts to access, modify,
              or misuse the data are strictly prohibited and may result in legal
              action.
            </Typography>
          </Box>

          <Divider sx={{ my: 2 }} />

          {/* Payment Policies Section */}
          <Box mb={4}>
            <Typography variant="h5" gutterBottom>
              2. Payment Policies
            </Typography>
            <Typography variant="body1">
              Payments for land-related services, including parcel registration
              and ownership verification, must be completed through approved
              channels. Transactions are non-refundable once processed.
            </Typography>
            <List>
              <ListItem>
                <ListItemText primary="All transactions are securely processed through the system." />
              </ListItem>
              <ListItem>
                <ListItemText primary="A payment confirmation receipt will be provided for each transaction." />
              </ListItem>
            </List>
          </Box>

          <Divider sx={{ my: 2 }} />

          {/* Search Information Section */}
          <Box mb={4}>
            <Typography variant="h5" gutterBottom>
              3. Land Parcel Search Information
            </Typography>
            <Typography variant="body1">
              Users can search for land parcels by ownership ID, plot number, or
              location. The information retrieved is accurate as per the latest
              records in the system. However, the system does not guarantee
              real-time updates on land ownership changes.
            </Typography>
            <List>
              <ListItem>
                <ListItemText primary="Search results include parcel details, ownership information, and status." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Historical data on land parcels may be available upon request." />
              </ListItem>
            </List>
          </Box>

          <Divider sx={{ my: 2 }} />

          {/* Compliance Section */}
          <Box mb={4}>
            <Typography variant="h5" gutterBottom>
              4. Compliance and Data Integrity
            </Typography>
            <Typography variant="body1">
              All users are responsible for ensuring the integrity and
              confidentiality of their access credentials. Any breach of this
              responsibility will result in immediate suspension of system
              access.
            </Typography>
          </Box>

          <Divider sx={{ my: 2 }} />

          {/* Agreement Section */}
          <Box mb={4}>
            <Typography variant="h5" gutterBottom>
              5. Agreement to Terms
            </Typography>
            <Typography variant="body1">
              By continuing to use the system, users acknowledge that they have
              read, understood, and agree to abide by these terms and
              conditions. The system reserves the right to update these terms at
              any time without prior notice.
            </Typography>
          </Box>
        </Paper>
      </Container>
      <Footer />
    </>
  );
}
