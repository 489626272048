import React from "react";
import { Box } from "@mui/material";
import NewsSection from "../components/Home/NewsSection";
import AboutSection from "../components/Home/AboutSection";
import FooterSection from "../components/Home/FooterSection";
import PartnerSection from "../components/Home/PartnerSection";
import Headers from "../components/Header/Header";
import HeroSection from "../components/Home/HeroSection";

export default function Landing() {
  return (
    <Box>
      {/* Header Section */}
      <Headers />

      {/* Hero Section */}
      <HeroSection />
      

      <NewsSection />
      <AboutSection />
      <PartnerSection />
      <FooterSection />
    </Box>
  );
}
