import React from "react";
import {
  Typography,
  Container,
  Paper,
  Divider,
  Box,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import Header from "../Header/Header";
import Footer from "../../components/Home/FooterSection";
import HeroSection from "../Home/HeroSection";

export default function PrivacyPolicy() {
  return (
    <>
      <Header />
      <HeroSection />
      <Container maxWidth="md" sx={{ mt: 12, mb: 3 }}>
        <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
          <Typography variant="h4" color="primary" gutterBottom align="center">
            Privacy Policy
          </Typography>
          <Divider sx={{ mb: 3 }} />

          {/* Data Collection Section */}
          <Box mb={4}>
            <Typography variant="h5" gutterBottom>
              1. Data Collection
            </Typography>
            <Typography variant="body1">
              We collect personal information such as your name, email address,
              and contact details when you register on the Homabay Land
              Information Management System. Additional information may be
              collected when you interact with our services or make
              transactions.
            </Typography>
            <List>
              <ListItem>
                <ListItemText primary="Information is collected through forms, registrations, and interactions." />
              </ListItem>
              <ListItem>
                <ListItemText primary="All data collected is essential for system operations and compliance." />
              </ListItem>
            </List>
          </Box>

          <Divider sx={{ my: 2 }} />

          {/* Data Usage Section */}
          <Box mb={4}>
            <Typography variant="h5" gutterBottom>
              2. Data Usage
            </Typography>
            <Typography variant="body1">
              The collected data is used to provide and improve our services,
              ensure system security, and comply with legal requirements. Your
              information will not be shared with third parties unless required
              by law or to fulfill service requests.
            </Typography>
            <List>
              <ListItem>
                <ListItemText primary="Data is used for identity verification, transaction processing, and system enhancements." />
              </ListItem>
              <ListItem>
                <ListItemText primary="We do not sell or rent your personal information." />
              </ListItem>
            </List>
          </Box>

          <Divider sx={{ my: 2 }} />

          {/* Data Storage Section */}
          <Box mb={4}>
            <Typography variant="h5" gutterBottom>
              3. Data Storage and Protection
            </Typography>
            <Typography variant="body1">
              Your data is stored securely using industry-standard encryption
              methods. Access to personal data is restricted to authorized
              personnel only.
            </Typography>
            <List>
              <ListItem>
                <ListItemText primary="Data is stored on secure servers with regular security audits." />
              </ListItem>
              <ListItem>
                <ListItemText primary="We implement measures to protect against unauthorized access and data breaches." />
              </ListItem>
            </List>
          </Box>

          <Divider sx={{ my: 2 }} />

          {/* User Rights Section */}
          <Box mb={4}>
            <Typography variant="h5" gutterBottom>
              4. User Rights
            </Typography>
            <Typography variant="body1">
              You have the right to access, modify, or delete your personal
              information. To exercise these rights, contact our support team.
            </Typography>
            <List>
              <ListItem>
                <ListItemText primary="Request access to your personal information." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Correct or update your data at any time." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Request deletion of your data, subject to legal constraints." />
              </ListItem>
            </List>
          </Box>

          <Divider sx={{ my: 2 }} />

          {/* Third-Party Services Section */}
          <Box mb={4}>
            <Typography variant="h5" gutterBottom>
              5. Third-Party Services
            </Typography>
            <Typography variant="body1">
              We may use trusted third-party services to enhance system
              functionality, such as payment processing and analytics. These
              services are obligated to protect your information and use it
              solely for the specified purposes.
            </Typography>
          </Box>

          <Divider sx={{ my: 2 }} />

          {/* Policy Updates Section */}
          <Box mb={4}>
            <Typography variant="h5" gutterBottom>
              6. Policy Updates
            </Typography>
            <Typography variant="body1">
              We reserve the right to update this privacy policy periodically.
              Changes will be communicated through the system, and continued use
              of our services implies acceptance of the updated policy.
            </Typography>
          </Box>

          {/* Contact Information Section */}
          <Box mt={4}>
            <Typography variant="h5" gutterBottom>
              7. Contact Information
            </Typography>
            <Typography variant="body1">
              For any questions or concerns about our privacy policy, please
              contact our support team at{" "}
              <strong>support@homabaylandinfo.gov</strong>.
            </Typography>
          </Box>
        </Paper>
      </Container>
      <Footer />
    </>
  );
}
