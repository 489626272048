import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Collapse,
  Divider,
  Stack,
  Pagination,
  Chip,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Container,
} from "@mui/material";
import { ArrowForwardIos, Add } from "@mui/icons-material";
import EnquiryDialog from "../components/parcels/EnquiryDialog";

export default function Support() {
  const [enquiries, setEnquiries] = useState([]);
  const [totalEnquiries, setTotalEnquiries] = useState(0);
  const [expandedEnquiryId, setExpandedEnquiryId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [column, setColumn] = useState("title");
  const [searchValue, setSearchValue] = useState("");
  const [open, setOpen] = useState(false); // Control the dialog visibility
  const [loading, setLoading] = useState(true);
  const limit = 10;
  const [user, setUser] = useState(null);
  const ownerId = localStorage.getItem("ownershipId");

  useEffect(() => {
    const fetchOwner = async () => {
      try {
        const response = await fetch(
          `/api/ownerships?id=${ownerId}&includeBeneficiaries=true`,
          { credentials: "include" }
        );
        if (!response.ok) throw new Error("Failed to fetch owner details");
        const { data } = await response.json();
        setUser(data[0]);
      } catch (error) {
        console.error(error);
      }
    };

    fetchOwner();
  }, [ownerId]);

  useEffect(() => {
    const fetchEnquiries = async () => {
      try {
        const offset = (currentPage - 1) * limit;
        const response = await fetch(
          `/api/enquiries?userId=${user?.id}&offset=${offset}&limit=${limit}`
        );
        if (response.ok) {
          const data = await response.json();
          setEnquiries(data.data);
          setTotalEnquiries(data.total);
        }
      } catch (error) {
        console.error("Error fetching enquiries:", error);
      }
    };

    if (user) fetchEnquiries(); // Ensure user data is loaded first
  }, [user, currentPage]);

  const handleExpandClick = (enquiryId) => {
    setExpandedEnquiryId((prevId) => (prevId === enquiryId ? null : enquiryId));
  };

  const handlePageChange = (_, page) => {
    setCurrentPage(page);
  };

  const handleDialogClose = () => {
    setOpen(false); // Close the dialog
  };

  return (
    <Paper elevation={2} sx={{ p: 2, mt: 6 }}>
      <Box display="flex" gap={2} alignItems="center">
        <Box flexGrow={1}>
          <Typography variant="h6" color="primary" sx={{ fontWeight: "600" }}>
            Support Issues
          </Typography>
        </Box>
        <FormControl>
          <InputLabel size="small">Search by...</InputLabel>
          <Select
            label="Search by..."
            size="small"
            onChange={(e) => setColumn(e.target.value)}
            value={column}
            sx={{ minWidth: "150px" }}
          >
            <MenuItem value="title">Title</MenuItem>
            <MenuItem value="name">Name</MenuItem>
            <MenuItem value="phone">Phone</MenuItem>
            <MenuItem value="category">Category</MenuItem>
          </Select>
        </FormControl>
        <TextField
          variant="outlined"
          size="small"
          label="Search..."
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)} // Add search handling
        />
        <Button
          color="primary"
          variant="contained"
          onClick={() => setOpen(true)} // Open the dialog
          startIcon={<Add />}
        >
          Add Issue
        </Button>
      </Box>

      <Box sx={{ margin: "1rem" }}>
        <Stack spacing={2}>
          {enquiries.map((enquiry) => (
            <Card
              key={enquiry.ID}
              sx={{
                boxShadow: "0px 4px 16px #60606040",
                borderRadius: "8px",
              }}
            >
              <CardContent sx={{ mb: 0, pb: 0 }}>
                <Typography display="block" variant="subtitle">
                  {enquiry.title}
                </Typography>
                <Chip
                  sx={{ position: "absolute", bottom: "1.5em", right: "1em" }}
                  label={enquiry.status ? "Resolved" : "Pending"}
                  color={enquiry.status ? "success" : "warning"}
                />
                <Typography
                  sx={{ position: "absolute", top: "1.5em", right: "1em" }}
                  variant="body2"
                  color="text.secondary"
                >
                  {new Date(enquiry.createdAt).toLocaleString()}
                </Typography>
                <Button
                  sx={{ textTransform: "capitalize" }}
                  variant="outlined"
                  size="small"
                  onClick={() => handleExpandClick(enquiry.ID)}
                >
                  <ArrowForwardIos
                    sx={{
                      rotate:
                        expandedEnquiryId === enquiry.ID ? "-90deg" : "90deg",
                      fontSize: "small",
                      mr: 1,
                    }}
                  />
                  {expandedEnquiryId === enquiry.ID
                    ? "Hide Details"
                    : "View Details"}
                </Button>
              </CardContent>
              <Collapse
                in={expandedEnquiryId === enquiry.ID}
                timeout="auto"
                unmountOnExit
              >
                <CardContent>
                  <Divider sx={{ mb: 1 }} />
                  {/* Enquiry details here */}
                  <Typography variant="body2" color="text.secondary">
                    <strong>Category:</strong> {enquiry.category}
                  </Typography>
                  {/* Add more fields */}
                </CardContent>
              </Collapse>
            </Card>
          ))}
        </Stack>
        <Pagination
          count={Math.ceil(totalEnquiries / limit)}
          page={currentPage}
          onChange={handlePageChange}
          sx={{ mt: 3 }}
        />
      </Box>

      {/* Add EnquiryDialog Component */}
      <EnquiryDialog open={open} onClose={handleDialogClose} />
    </Paper>
  );
}
